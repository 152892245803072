<template>
  <section>
    <div class="container tablet-container">
      <div class="row no-gutters">
        <div class="nb_wrapper">
          <div class="nb__col">
            <div class="nb__header">
              <h2 class="nb__title">{{ $t('Новости') }}</h2>
              <uc-link
                class="nb__more"
                title="Смотреть все"
                aria-label="Смотреть все новости"
                :to="localePath({ name: 'news-page' })"
                >{{ $t('Смотреть все') }}</uc-link
              >
            </div>
            <div class="nb__news">
              <div v-for="(news, index) in slicedNews" :key="`news-${index}`" class="nb__news-item">
                <uc-link
                  v-if="news.slug"
                  :title="news.h1"
                  :aria-label="news.h1"
                  :to="localePath({ name: 'news-detail-page', params: { slug: news.slug } })"
                >
                  <div class="nb__brands-item_img">
                    <uc-nuxt-image
                      :img="news.mainImage"
                      :alt="news.slug"
                      width="300"
                      height="300"
                      folder="content-image"
                    />
                  </div>
                </uc-link>
              </div>
            </div>
          </div>
          <div class="nb__col">
            <div class="nb__header">
              <h2 class="nb__title">{{ $t('Бренды') }}</h2>
              <uc-link
                class="nb__more"
                title="Смотреть все"
                aria-label="Смотреть все бренды"
                :to="localePath({ name: 'brand-list' })"
              >
                {{ $t('Смотреть все') }}
              </uc-link>
            </div>
            <div class="nb__brands">
              <div v-for="(brand, index) in slicedBrands" :key="`brands-item-${index}`" class="nb__brands-item">
                <uc-link :title="brand.alt" :aria-label="brand.alt" :to="brand.link">
                  <div class="nb__brands-item_img">
                    <uc-nuxt-image :img="brand.img" :alt="brand.alt" width="100" height="56" folder="slider-image" />
                  </div>
                </uc-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const QUANTITY_NEWS_MOBILE = 3;
const QUANTITY_NEWS_TABLET = 6;
const QUANTITY_NEWS_DESKTOP = 4;

const QUANTITY_BRANDS_MOBILE = 4;
const QUANTITY_BRANDS_TABLET = 6;
const QUANTITY_BRANDS_DESKTOP = 8;

export default {
  inject: ['LAYOUT_PROVIDES'],
  props: {
    newsSlides: {
      type: Object,
      default: () => ({})
    },
    brandSlides: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    counts() {
      if (this.LAYOUT_PROVIDES.SCREEN_SIZES.IS_SCREEN_MOBILE) {
        return { news: QUANTITY_NEWS_MOBILE, brands: QUANTITY_BRANDS_MOBILE };
      }
      if (this.LAYOUT_PROVIDES.SCREEN_SIZES.IS_SCREEN_TABLET_AND_LESS) {
        return { news: QUANTITY_NEWS_TABLET, brands: QUANTITY_BRANDS_TABLET };
      }
      return { news: QUANTITY_NEWS_DESKTOP, brands: QUANTITY_BRANDS_DESKTOP };
    },
    slicedNews() {
      if (!this.newsSlides?.slides?.length) {
        return [];
      }
      return this.newsSlides.slides.slice(0, this.counts.news);
    },
    slicedBrands() {
      if (!this.brandSlides?.length) {
        return [];
      }
      return this.brandSlides.slice(0, this.counts.brands);
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/news-and-brands/_news-and-brands.scss" />
