<template>
  <div :class="isMobile ? 'main-banners-mobile' : 'main-banners'">
    <div class="container tablet-container">
      <div class="row no-gutters">
        <div class="main-banners__wrapper">
          <div v-for="(slide, index) in visibleSlides" :key="`main-banners-slide-${index}`" class="main-banners__slide">
            <uc-link v-show="!slide.isBlank" :title="slide.title" :aria-label="slide.title" :to="slide.link"
              ><uc-nuxt-image
                :img="slide.img"
                :alt="slide.alt"
                folder="slider-image"
                @click.native="sendToGtmSelected(slide, index)"
            /></uc-link>
            <uc-link
              v-show="slide.isBlank"
              target="_blank"
              :title="slide.title"
              :aria-label="slide.title"
              :to="slide.link"
              ><uc-nuxt-image
                :img="slide.img"
                :alt="slide.alt"
                folder="slider-image"
                @click.native="sendToGtmSelected(slide, index)"
            /></uc-link>
            <uc-link :to="localePath({ name: 'promotion-page' })" class="main-banners__slide-button">{{
              $t('Все акции')
            }}</uc-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['LAYOUT_PROVIDES'],
  props: {
    slides: {
      type: Array,
      required: true
    },
    mobileSlides: {
      type: Array,
      required: true
    }
  },
  computed: {
    isMobile() {
      return this.LAYOUT_PROVIDES.SCREEN_SIZES.IS_SCREEN_TABLET_AND_LESS;
    },
    visibleSlides() {
      return this.isMobile ? this.mobileSlides : this.slides;
    }
  },
  mounted() {
    this.sendToGtmViewed();
  },
  methods: {
    getGtmPayload(slide, position) {
      if (!slide) {
        return null;
      }
      return {
        promotion_id: slide.promotion_id,
        promotion_name: slide.promotion_name,
        creative_slot: position + 1
      };
    },
    sendToGtmViewed() {
      const payload = this.visibleSlides.map(this.getGtmPayload);
      this.$gtm.setMultiplePromotionAction(payload, this.$gtm.constructor.PROMOTION_VIEW_KEY);
    },
    sendToGtmSelected(slide, index) {
      this.$gtm.setPromotionAction(this.getGtmPayload(slide, index), this.$gtm.constructor.PROMOTION_SELECT_KEY);
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/main-banners/_main-banners.scss" />
