<template>
  <main class="main">
    <LazyHydrate when-idle>
      <section>
        <main-banners :slides="mainSlides" :mobile-slides="mobileMainSlides" />
      </section>
    </LazyHydrate>

    <LazyHydrate when-visible>
      <news-and-brands :news-slides="newsSlider" :brand-slides="brands" />
    </LazyHydrate>

    <LazyHydrate when-visible>
      <blog-and-bonus :blog-slides="blogSlider" />
    </LazyHydrate>

    <LazyHydrate when-visible>
      <main-description :description="mainDescription" />
    </LazyHydrate>
  </main>
</template>

<script>
import MainBanners from '~/src/components/main/MainBanners.vue';
import NewsAndBrands from '~/src/components/main/NewsAndBrands.vue';
import BlogAndBonus from '~/src/components/main/BlogAndBonus.vue';
import MainDescription from '~/src/components/page-blocks/MainDescription.vue';
import ScrollTop from '~/src/mixins/ScrollToTop.mixin';
import MainSsrMixin from '~/src/components/main/mixins/MainSsr.mixin.js';
import LazyHydrate from 'vue-lazy-hydration';

export default {
  name: 'MainPage',
  components: {
    MainBanners,
    NewsAndBrands,
    MainDescription,
    BlogAndBonus,
    LazyHydrate
  },
  mixins: [ScrollTop, MainSsrMixin],
  beforeMount() {
    this.scrollToTopMain();
  }
};
</script>
