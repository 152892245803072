// @deprecated
// @todo: replace to u-mixins
export default {
  computed: {
    isBonusProgramMember() {
      return this.$store.getters['user/isBonusProgramMember'];
    },
    isBonusServiceEnable() {
      return this.$store.getters['bonus/isBonusServiceEnable'];
    },
    isActiveBonusProgram: () => process.env.BONUS_PROGRAM,
    hasBonusProfile() {
      return !!this.$store.state.bonus.profile;
    }
  }
};
