import { bonusStore } from 'u-store';
const { state, mutations, getters, actions } = bonusStore;

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
