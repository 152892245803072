import wishListService from 'u-wishlist/lib/services/WishList.service.js';
import { ContextServices, Injector } from 'u-services';
import CatalogUrlService from 'u-catalog-url';
import CatalogService from 'u-catalog/lib/services/Catalog.service.js';
import MetaService from 'u-meta/lib/Meta.service.js';

export default (ctx, inject) => {
  const injector = new Injector(ctx, inject);
  const dependencyList = [
    { service: ContextServices.AuthService, alias: 'auth' },
    { service: ContextServices.BonusService, alias: 'bonus' },
    { service: ContextServices.ContentService, alias: 'content' },
    { service: MetaService, alias: 'metaService' },
    { service: ContextServices.UtilsService, alias: 'utils' },
    { service: CatalogService, alias: 'catalog' },
    { service: wishListService, alias: 'wishList' }
  ];

  ctx['$catalogUrlService'] = CatalogUrlService;
  inject('catalogUrlService', CatalogUrlService);

  injector.injectDependencyList(dependencyList);
};
