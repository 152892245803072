// @deprecated
// @todo: replace to u-mixins
const SMALL_MOBILE_SCREEN = 576;
const MOBILE_SCREEN = 768;
const TABLET_SCREEN = 1024;
const SMALL_DESKTOP_SCREEN = 1366;
// eslint-disable-next-line
const FULL_HD_DESKTOP_SCREEN = 1920;

export default {
  data() {
    return {
      screenWidth: SMALL_DESKTOP_SCREEN //by default, in beforeMount() global.innerWidth
    };
  },
  computed: {
    isScreenSmallMobile() {
      return this.screenWidth < SMALL_MOBILE_SCREEN;
    },
    isScreenMobile() {
      return this.screenWidth < MOBILE_SCREEN;
    },
    isScreenTablet() {
      return this.screenWidth >= MOBILE_SCREEN && this.screenWidth < TABLET_SCREEN;
    },
    isScreenTabletAndLess() {
      return this.screenWidth < TABLET_SCREEN;
    },
    isScreenSmallDesktopAndLess() {
      return this.screenWidth < SMALL_DESKTOP_SCREEN;
    },
    isScreenLessOrEqualTablet() {
      return this.screenWidth <= TABLET_SCREEN;
    },
    isScreenSmallDesktop() {
      return this.screenWidth >= TABLET_SCREEN && this.screenWidth < SMALL_DESKTOP_SCREEN;
    },
    isScreenDesktop() {
      return this.screenWidth >= SMALL_DESKTOP_SCREEN;
    },
    isScreenAllDesktop() {
      return this.screenWidth >= TABLET_SCREEN;
    },
    isScreenFullHd() {
      return this.screenWidth >= SMALL_DESKTOP_SCREEN;
    }
  },
  beforeMount() {
    this.screenWidth = global.innerWidth;
  },
  mounted() {
    global.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    global.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    handleWindowResize() {
      this.screenWidth = global.innerWidth;
    }
  }
};
