import { orderStore } from 'u-store';
const { state, mutations, mutationTypes, actions, getters } = orderStore;

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  mutationTypes,
  getters
};
