import { ContentServices } from 'u-services';
const Service = ContentServices.MainService;

export default {
  asyncData($context) {
    const mainPageService = new Service($context);
    return Promise.all([
      mainPageService.getMainBanners($context.app.i18n.locale),
      mainPageService.getMobileMainBanners($context.app.i18n.locale),
      mainPageService.getBrandSlides($context.app.i18n.locale),
      mainPageService.getBlogSlider($context.app.i18n.locale),
      mainPageService.getNewsSlider($context.app.i18n.locale),
      mainPageService.getMainDescription($context.app.i18n.locale)
    ])
      .then((data) => $context.$utils.clearResponseData(data))
      .catch((error) => {
        if (error?.response?.data?.code === 404) {
          return null;
        }

        console.error('Main ssr Error:', error);
      });
  },
  data() {
    return {
      mainSlides: [],
      mobileMainSlides: [],
      categories: [],
      brands: [],
      blogSlider: {},
      newsSlider: {},
      mainDescription: ''
    };
  }
};
