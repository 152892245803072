export default {
  дней: 'дней | день | дня | дней',
  недель: '{n} недель | {n} неделя | {n} недели | {n} недель',
  часов: 'часов | час | часа | часов',
  лет: 'лет | год | года | лет',
  минут: 'минут | минута | минуты | минут',
  секунд: 'секунд | секунда | секунды | секунд',
  товаров: '0 товаров | {n} товар | {n} товара | {n} товаров',
  отзывов: '0 отзывов | {n} отзыв | {n} отзыва | {n} отзывов',
  бонусов: 'бонусов | бонус | бонуса | бонусов',
  выбрано: 'выбрано | выбран | выбрано | выбрано',
  скидок: 'скидок | скидка | скидки | скидок',
  //MAIN
  Покупателю: 'Покупателю',
  'О нас': 'О нас',
  'О магазине': 'О магазине',
  'Вопросы и ответы': 'Вопросы и ответы',
  'Наши магазины': 'Наши магазины',
  'Доставка и оплата': 'Доставка и оплата',
  'Гарантия и сервис': 'Гарантия и сервис',
  'Бонусная программа': 'Бонусная программа',
  'Акции и распродажи': 'Акции и распродажи',
  'Пакунок малюка': 'Пакунок малюка',
  'Подарочные сертификаты': 'Подарочные сертификаты',
  'Возврат и обмен': 'Возврат и обмен',
  Блог: 'Блог',
  Корзина: 'Корзина',
  'Вернуться в магазин': 'Вернуться в магазин',
  Экономия: 'Экономия',
  'В корзину': 'В корзину',
  'Нет в наличии': 'Нет в наличии',
  'Оформить заказ': 'Оформить заказ',
  'Общая сумма': 'Общая сумма',
  Избранное: 'Избранное',
  'Каталог товаров': 'Каталог товаров',
  Бренды: 'Бренды',
  'Мой кабинет': 'Мой кабинет',
  Войти: 'Войти',
  Регистрация: 'Регистрация',
  'История заказов': 'История заказов',
  'Личная информация': 'Личная информация',
  'Мои отзывы': 'Мои отзывы',
  'Список желаний': 'Список желаний',
  'Добавить в cписок желаний?': 'Добавить в cписок желаний?',
  'Результаты поиска': 'Результаты поиска',
  'Не найдено результатов по запросу': 'Не найдено результатов по запросу',
  'Результаты поиска по запросу': 'Результаты поиска по запросу',
  'Показаны результаты по запросу': 'Показаны результаты по запросу',
  'построить маршрут': 'построить маршрут',
  'Чтобы управлять картой, коснитесь её 2 раза': 'Чтобы управлять картой, коснитесь её 2 раза',
  Выйти: 'Выйти',
  'График работы': 'График работы',
  'Будние дни с 8 до 21': 'Будние дни с 8 до 21',
  'Выходные с 8 до 20': 'Выходные с 8 до 20',
  Ещё: 'Ещё',
  Поиск: 'Поиск',
  'Говорите...': 'Говорите...',
  'Все результаты': 'Все результаты',
  'Все акции': 'Все акции',
  Новинки: 'Новинки',
  Новости: 'Новости',
  'Смотреть все': 'Смотреть все',
  'Воспользуйтесь скидкой уже сейчас или соберите еще': 'Воспользуйтесь скидкой уже сейчас или соберите еще',
  'бонусов для получения': 'бонусов для получения',
  'Станьте участником': 'Станьте участником',
  'бонусной программы': 'бонусной программы',
  'Присоединяйтесь к бонусной программе': 'Присоединяйтесь к бонусной программе',
  'Накапливайте кешбэк за каждую покупку и выбирайте лучшее для своих детей!':
    'Накапливайте кешбэк за каждую покупку и выбирайте лучшее для своих детей!',
  'Условия покупки': 'Условия покупки',
  'Ваши вопросы (FAQ)': 'Ваши вопросы (FAQ)',
  Подписаться: 'Подписаться',
  'Подпишитесь и получайте новости об акциях и специальных предложениях':
    'Подпишитесь и получайте новости об акциях и специальных предложениях',
  'Наши контакты': 'Наши контакты',
  поддержки: 'поддержки',
  'Все права защищены': 'Все права защищены',
  'Call-центра': 'Call-центра',
  'Назад к покупкам': 'Назад к покупкам',
  'ООО "MA"': 'ООО "MA"',
  'Ваша корзина пуста': 'Ваша корзина пуста',
  'Ознакомьтесь с': 'Ознакомьтесь с',
  нашими: 'нашими',
  акциями: 'акциями',
  'К сожалению, товар': 'К сожалению, товар',
  'из Вашей корзины закончился': 'из Вашей корзины закончился',
  'Смотреть похожие': 'Смотреть похожие',
  'К сожалению, некоторые товары из вашей корзины закончились':
    'К сожалению, некоторые товары из вашей корзины закончились',
  'Перейти в корзину': 'Перейти в корзину',
  'в запрашиваемом количестве недоступен': 'в запрашиваемом количестве недоступен',
  'К сожалению, некоторые товары из вашей корзины недоступны':
    'К сожалению, некоторые товары из вашей корзины недоступны',
  'Из какого магазина забрать': 'Из какого магазина забрать',
  'Вам нужно выбрать магазин из списка': 'Вам нужно выбрать магазин из списка',
  'Пожалуйста, введите свой телефон': 'Пожалуйста, введите свой телефон',
  'Мы отправим на этот номер код подтверждения': 'Мы отправим на этот номер код подтверждения',
  'пн-вс: с': 'пн-вс: с',
  //MAIN
  //CATALOG
  Сортировать: 'Сортировать',
  Фильтр: 'Фильтр',
  Назад: 'Назад',
  Показать: 'Показать',
  Предзаказ: 'Предзаказ',
  топ: 'топ',
  акция: 'акция',
  'Оставить отзыв': 'Оставить отзыв',
  Опубликовать: 'Опубликовать',
  'Для мам': 'Для мам',
  Возраст: 'Возраст',
  'Для мальчиков': 'Для мальчиков',
  'Для девочек': 'Для девочек',
  'Сервис и гарантия': 'Сервис и гарантия',
  Категории: 'Категории',
  Ожидается: 'Ожидается',
  Еще: 'Еще',
  'Показать еще': 'Показать еще',
  'Ничего не найдено': 'Ничего не найдено',
  'Очистить фильтры': 'Очистить фильтры',
  'по рейтингу': 'по рейтингу',
  новинки: 'новинки',
  'максимальная скидка': 'максимальная скидка',
  'по возрастанию цены': 'по возрастанию цены',
  'по убыванию цены': 'по убыванию цены',
  //CATALOG
  //CABINET
  'Личный кабинет': 'Личный кабинет',
  Выход: 'Выход',
  Дата: 'Дата',
  заказа: 'заказа',
  Подробнее: 'Подробнее',
  Собирается: 'Собирается',
  'Передан на доставку': 'Передан на доставку',
  'В пути': 'В пути',
  Доставлен: 'Доставлен',
  'Частично собран': 'Частично собран',
  Собран: 'Собран',
  Способ: 'Способ',
  Получатель: 'Получатель',
  'Оплата наличными': 'Оплата наличными',
  'Оплата картой в магазине': 'Оплата картой в магазине',
  'Ожидание оплаты': 'Ожидание оплаты',
  'Обработка платежа': 'Обработка платежа',
  Оплачено: 'Оплачено',
  Отмена: 'Отмена',
  Просрочено: 'Просрочено',
  Заказ: 'Заказ',
  от: 'от',
  до: 'до',
  'Если вы хотите вернуть товар': 'Если вы хотите вернуть товар',
  'Отменить заказ': 'Отменить заказ',
  Редактировать: 'Редактировать',
  'Мой основной адрес': 'Мой основной адрес',
  'Сделать основным': 'Сделать основным',
  Добавить: 'Добавить',
  'Изменить Пароль': 'Изменить Пароль',
  'Фамилия и имя': 'Фамилия и имя',
  Пол: 'Пол',
  мужской: 'мужской',
  женский: 'женский',
  'Дата рождения': 'Дата рождения',
  Месяц: 'Месяц',
  Год: 'Год',
  Дом: 'Дом',
  дом: 'дом',
  Квартира: 'Квартира',
  'Курьер по адресу': 'Курьер по адресу',
  Отчество: 'Отчество',
  'Изменение пароля': 'Изменение пароля',
  'Текущий пароль': 'Текущий пароль',
  'Новый пароль': 'Новый пароль',
  'Подтвердите пароль': 'Подтвердите пароль',
  'Текущий пароль указано неверно': 'Текущий пароль указано неверно',
  'Связь с социальными сетями для входа в один клик': 'Связь с социальными сетями для входа в один клик',
  Отвязать: 'Отвязать',
  Связать: 'Связать',
  Сохранить: 'Сохранить',
  Cохранено: 'Cохранено',
  'Пароль успешно изменен': 'Пароль успешно изменен',
  'Личная информация обновлена': 'Личная информация обновлена',
  'Email уже установлен или подтвержден': 'Email уже установлен или подтвержден',
  'Ошибка сохранения email': 'Ошибка сохранения email',
  'Успешно сохранено': 'Успешно сохранено',
  'Социальная сеть отключена': 'Социальная сеть отключена',
  'Социальная сеть подключена': 'Социальная сеть подключена',
  'Ошибка добавления соцсети': 'Ошибка добавления соцсети',
  'Пользователь с данным идентификатором уже существует': 'Пользователь с данным идентификатором уже существуетт',
  'Информация о детях': 'Информация о детях',
  'Для добавления более 3-х детей обратитесь в контакт-центр':
    'Для добавления более 3-х детей обратитесь в контакт-центр',
  'Информация о ребенке сохранена': 'Информация о ребенке сохранена',
  'Информация о предварительной дате рождения ребенка сохранена':
    'Информация о предварительной дате рождения ребенка сохранена',
  'Информация о ребенке обновлена': 'Информация о ребенке обновлена',
  'Информация о предварительной дате рождения ребенка обновлена':
    'Информация о предварительной дате рождения ребенка обновлена',
  'Информация о ребенке удалена': 'Информация о ребенке удалена',
  'Информация о предварительной дате рождения ребенка удалена':
    'Информация о предварительной дате рождения ребенка удалена',
  мальчик: 'мальчик',
  девочка: 'девочка',
  Мальчик: 'Мальчик',
  Девочка: 'Девочка',
  Удалить: 'Удалить',
  'Удаление информации о ребенке': 'Удаление информации о ребенке',
  'Вы уверены, что хотите удалить информацию о предварительной дате рождения ребенка?':
    'Вы уверены, что хотите удалить информацию о предварительной дате рождения ребенка?',
  Да: 'Да',
  'На ваш адрес': 'На ваш адрес',
  'отправлено подтверждение': 'отправлено подтверждение',
  'Письмо с подтверждением адреса действительно в течении 24 часов':
    'Письмо с подтверждением адреса действительно в течении 24 часов',
  'Мои адреса': 'Мои адреса',
  'Информация о адресе добавлена': 'Информация о адресе добавлена',
  'Информация о адресе обновлена': 'Информация о адресе обновлена',
  'Информация о адресе удалена': 'Информация о адресе удалена',
  'Успешно удалено': 'Успешно удалено',
  Отделение: 'Отделение',
  'Отделение перевозчика': 'Отделение перевозчика',
  'Отсутствует в данном населенном пункте': 'Отсутствует в данном населенном пункте',
  'Адресная доставка': 'Адресная доставка',
  'Номер отделения': 'Номер отделения',
  'Удаление адреса': 'Удаление адреса',
  'Вы уверены, что хотите удалить адрес?': 'Вы уверены, что хотите удалить адрес?',
  'Вы еще не оставляли отзывы': 'Вы еще не оставляли отзывы',
  Достоинства: 'Достоинства',
  'Ваша оценка': 'Ваша оценка',
  'Одно из трех полей обязательно для заполнения': 'Одно из трех полей обязательно для заполнения',
  Недостатки: 'Недостатки',
  'Сохранить отзыв': 'Сохранить отзыв',
  Изменить: 'Изменить',
  'Показать все': 'Показать все',
  'Скрыть все': 'Скрыть все',
  комментарии: 'комментарии',
  все: 'все',
  'Удаление отзыва': 'Удаление отзыва',
  'Вы уверены, что хотите удалить отзыв?': 'Вы уверены, что хотите удалить отзыв?',
  'Ваш отзыв успешно обновлен, отправлен на обработку и будет отображен в ближайшее время':
    'Ваш отзыв успешно обновлен, отправлен на обработку и будет отображен в ближайшее время',
  'Списки желаний': 'Списки желаний',
  'Авторизуйтесь что бы воспользоваться всеми возможностями списка желаний':
    'Авторизуйтесь что бы воспользоваться всеми возможностями списка желаний',
  'Ваш список желаний пока пуст': 'Ваш список желаний пока пуст',
  'Добавляйте любимые товары в список, чтобы не забыть о них':
    'Добавляйте любимые товары в список, чтобы не забыть о них',
  'Добавить список': 'Добавить список',
  'Новый список желаний': 'Новый список желаний',
  'Введите название списка': 'Введите название списка',
  'Добавив в него товары, вы можете вернуться к списку покупок в любое удобное время, а также поделиться им со своими друзьями и обсудить предстоящую покупку вместе':
    'Добавив в него товары, вы можете вернуться к списку покупок в любое удобное время, а также поделиться им со своими друзьями и обсудить предстоящую покупку вместе',
  'Редактировать название': 'Редактировать название',
  'Введите название': 'Введите название',
  'Ред.название': 'Ред.название',
  'Удалить список': 'Удалить список',
  'Вы точно хотите безвозвратно удалить свой список желаний':
    'Вы точно хотите безвозвратно удалить свой список желаний',
  'вместе с добавленными в него товарами': 'вместе с добавленными в него товарами',
  'По умолчанию': 'По умолчанию',
  Купить: 'Купить',
  Переместить: 'Переместить',
  'Переместить в список': 'Переместить в список',
  'Переместить в новый список': 'Переместить в новый список',
  'Cсылка скопирована': 'Cсылка скопирована',
  'Упс. Программа МА Кешбэк на профилактике. Попробуйте обновить страницу через несколько минут':
    'Упс. Программа МА Кешбэк на профилактике. Попробуйте обновить страницу через несколько минут',
  'Для участия в программе МА Кешбэк необходимо': 'Для участия в программе МА Кешбэк необходимо',
  'заполнить профиль': 'заполнить профиль',
  'Узнайте больше о преимуществах статусов в программе': 'Узнайте больше о преимуществах статусов в программе',
  'Обменивайте бонусы на скидку': 'Обменивайте бонусы на скидку',
  'Используйте бонусы для получения скидки при оплате продуктов собственного импорта! Чем больше бонусов – тем выгодней обмен!':
    'Используйте бонусы для получения скидки при оплате продуктов собственного импорта! Чем больше бонусов – тем выгодней обмен!',
  'Доступно кешбэка': 'Доступно кешбэка',
  'Доступно МА Кешбэка': 'Доступно МА Кешбэка',
  'Воспользуйтесь скидкой сейчас': 'Воспользуйтесь скидкой сейчас',
  'или соберите еще': 'или соберите еще',
  'Соберите еще': 'Соберите еще',
  'Кешбэк начисляется на 16-й день после покупки': 'Кешбэк начисляется на 16-й день после покупки',
  'Оплачивайте МА Кешбэком до 50% от стоимости продуктов, обозначенных диаМАнтом':
    'Оплачивайте МА Кешбэком до 50% от стоимости продуктов, обозначенных диаМАнтом💎',
  'Кешбэк к начислению': 'Кешбэк к начислению',
  Подробно: 'Подробно',
  Кешбэк: 'Кешбэк',
  'Стать участником программы': 'Стать участником программы',
  'Стать участником программы МА Кешбэк': 'Стать участником программы МА Кешбэк',
  'Я ожидаю ребенка': 'Я ожидаю ребенка',
  'Предварительная дата': 'Предварительная дата',
  'Предварительная дата родов': 'Предварительная дата родов',
  "'Предварительная дата родов' обязательно для заполнения": "'Попередня дата пологів' обязательно для заполнения",
  'В счастливом ожидании малыша': 'В счастливом ожидании малыша',
  'Невозможно сохранить': 'Невозможно сохранить',
  'Чтобы стать участником программы МА Кешбэк нужно заполнить поля: Имя, Фамилия, Город, Пол, Email':
    'Чтобы стать участником программы МА Кешбэк нужно заполнить поля: Имя, Фамилия, Город, Пол, Email',
  'Легко присоединиться': 'Легко присоединиться',
  'Просто заполните анкету, чтобы стать участником бонусной программы':
    'Просто заполните анкету, чтобы стать участником бонусной программы',
  'Весело покупать': 'Весело покупать',
  'За каждую покупку с': 'За каждую покупку с',
  'вы получаете бонусы, которые активируются через 15 дней.':
    'вы получаете бонусы, которые активируются через 15 дней.',
  '1 потраченная гривна': '1 потраченная гривна',
  '1 накопленный бонус': '1 накопленный бонус',
  'это 1 бонус на вашем счете. Бонусы начисляются даже во время акций или распродаж.':
    'это 1 бонус на вашем счете. Бонусы начисляются даже во время акций или распродаж.',
  'Приятно бейбишопиться': 'Приятно бейбишопиться',
  'Используйте бонусы для оплаты любых товаров! Чем больше бонусов - тем выгоднее скидка!':
    'Используйте бонусы для оплаты любых товаров! Чем больше бонусов - тем выгоднее скидка!',
  'Быстро накапливать': 'Быстро накапливать',
  'Бонусы накапливаются быстрее при участии в акциях или распродажах и с увеличением суммы покупок.':
    'Бонусы накапливаются быстрее при участии в акциях или распродажах и с увеличением суммы покупок.',
  'Сумма покупок*': 'Сумма покупок*',
  'Коэффициент начисления бонусов': 'Коэффициент начисления бонусов',
  'Бонусы при получении статуса': 'Бонусы при получении статуса',
  'бонусы в День рождения вашего ребенка': 'бонусы в День рождения вашего ребенка',
  'Бонусы не сгорают': 'Бонусы не сгорают',
  'Подарок на День рождения вашего ребенка (до 3-х лет)': 'Подарок на День рождения вашего ребенка (до 3-х лет)',
  'Доступ к избранным акциям за день до начала': 'Доступ к избранным акциям за день до начала',
  'Доступ к избранным акциям': 'Доступ к избранным акциям',
  'Обмен товаров до 30 дней (вместо 14 дней)': 'Обмен товаров до 30 дней (вместо 14 дней)',
  '2000 бонусов в подарок 1 раз в год': '2000 бонусов в подарок 1 раз в год',
  '*Бонусная карта действует на всей территории Украины в фирменных магазинах Chicco (адреса магазинов на сайте chicco.com.ua), в интернет-магазине chicco.com.ua, в фирменных магазинах Stokke (адреса магазинов на сайте Stokke.com.ua) и в интернет-магазине Stokke.com.ua и в фирменных магазинах Babyshop (адреса магазинов на сайте babyshop.ua).':
    '*Бонусная карта действует на всей территории Украины в фирменных магазинах Chicco (адреса магазинов на сайте chicco.com.ua), в интернет-магазине chicco.com.ua, в фирменных магазинах Stokke (адреса магазинов на сайте Stokke.com.ua) и в интернет-магазине Stokke.com.ua и в фирменных магазинах Babyshop (адреса магазинов на сайте babyshop.ua).',
  'Более детально с правилами программы МА Кешбэк вы можете ознакомиться здесь:':
    'Более детально с правилами программы МА Кешбэк вы можете ознакомиться здесь:',
  'Правила Кешбэк программы': 'Правила Кешбэк программы',
  Партнеры: 'Партнеры',
  'Дата начисления': 'Дата начисления',
  'Совершите покупку, чтобы получить кешбэк': 'Совершите покупку, чтобы получить кешбэк',
  'Ваш статус': 'Ваш статус',
  Получайте: 'Получайте',
  'за каждую потраченную гривну': 'за каждую потраченную гривну',
  'Сумма покупок за текущий период': 'Сумма покупок за текущий период',
  Для: 'Для',
  сохранения: 'сохранения',
  'получения следующего': 'получения следующего',
  статуса: 'статуса',
  'необходимо купить на сумму': 'необходимо купить на сумму',
  'до конца текущего года': 'до конца текущего года',
  'Вы достигли максимального статуса, так держать': 'Вы достигли максимального статуса, так держать',
  'Статус действует по': 'Статус действует по',
  'История начислений': 'История начислений',
  Событие: 'Событие',
  Детали: 'Детали',
  'Начисление за событие': 'Начисление за событие',
  Начисление: 'Начисление',
  Приглашение: 'Приглашение',
  Списание: 'Списание',
  'Ежегодный подарок': 'Ежегодный подарок',
  'Переход на следующий уровень': 'Переход на следующий уровень',
  'заказ №': 'заказ №',
  'Первый отзыв о товаре': 'Первый отзыв о товаре',
  'Подписка на рассылку по e-mail': 'Подписка на рассылку по e-mail',
  'Отзыв о товаре': 'Отзыв о товаре',
  'Начисление за первую покупку друга': 'Начисление за первую покупку друга',
  "Поле 'Город' необходимо иметь не менее 2-х буквенных символов":
    "Поле 'Город' необходимо иметь не менее 2-х буквенных символов",
  //CABINET
  //DETAIL-PAGE
  'Подписаться на товар': 'Подписаться на товар',
  'Выбирите период': 'Выбирите период',
  'Варианты покупки': 'Варианты покупки',
  'В наличии': 'В наличии',
  'Доставка от 2-х дней': 'Доставка от 2-х дней',
  Код: 'Код',
  товара: 'товара',
  Цвет: 'Цвет',
  'Ваша выгода': 'Ваша выгода',
  Выгода: 'Выгода',
  'Добавить в корзину': 'Добавить в корзину',
  'снято с производства': 'снято с производства',
  'С этим товаром рекомендуем': 'С этим товаром рекомендуем',
  Фото: 'Фото',
  Видео: 'Видео',
  Описание: 'Описание',
  Характеристики: 'Характеристики',
  Отзывы: 'Отзывы',
  'Отзывов о данном товаре пока не оставляли': 'Отзывов о данном товаре пока не оставляли',
  'Станьте первым и получите 500 бонусов': 'Станьте первым и получите 500 бонусов',
  'бонусов за отзыв': 'бонусов за отзыв',
  'бонусы начисляются за информативный и одобренный модераторами отзыв':
    'бонусы начисляются за информативный и одобренный модераторами отзыв',
  'Больше отзывов': 'Больше отзывов',
  'Ваш отзыв отправлен на обработку и появится в ближайшее время':
    'Ваш отзыв отправлен на обработку и появится в ближайшее время',
  'Ошибка сохранения': 'Ошибка сохранения',
  'Ошибка удаления': 'Ошибка удаления',
  'Создать список': 'Создать список',
  'Добавить в список': 'Добавить в список',
  'Добавить в новый список': 'Добавить в новый список',
  'Отзывы ': 'Отзывы ',
  Инструкция: 'Инструкция',
  'Читать инструкцию': 'Читать инструкцию',
  'Наличие в магазинах': 'Наличие в магазинах',
  'Отправить по email': 'Отправить по email',
  Поделиться: 'Поделиться',
  'Скопировать ссылку': 'Скопировать ссылку',
  'В список желаний': 'В список желаний',
  'В списке желаний': 'В списке желаний',
  'на ваш счет при покупке': 'на ваш счет при покупке',
  'Узнайте больше о бонусной программе': 'Узнайте больше о бонусной программе',
  'Все видео': 'Все видео',
  'Все отзывы': 'Все отзывы',
  'Все о товаре': 'Все о товаре',
  'Вы недавно смотрели': 'Вы недавно смотрели',
  'Вы добавили в корзину': 'Вы добавили в корзину',
  'на сумму': 'на сумму',
  Наименование: 'Наименование',
  Цена: 'Цена',
  'Кол-во': 'Кол-во',
  Сумма: 'Сумма',
  Продолжить: 'Продолжить',
  'Продолжить покупки': 'Продолжить покупки',
  Город: 'Город',
  Адрес: 'Адрес',
  'К сожалению, в данный момент в городе': 'К сожалению, в данный момент в городе',
  'товара нет в наличии': 'товара нет в наличии',
  'Выберите другой город': 'Выберите другой город',
  'Выберите другой цвет или размер': 'Выберите другой цвет или размер',
  'В данный момент товар отсутствует в фирменных магазинах': 'В данный момент товар отсутствует в фирменных магазинах',
  //DETAIL-PAGE
  //ORDER
  'Единоразовая покупка': 'Единоразовая покупка',
  'Доставлять каждые': 'Доставлять каждые',
  'Сумма без скидки': 'Сумма без скидки',
  'Сохранить корзину': 'Сохранить корзину',
  'У вас есть промокод': 'У вас есть промокод',
  'При покупке будет начислено': 'При покупке будет начислено',
  'Номер дома': 'Номер дома',
  'Номер квартиры': 'Номер квартиры',
  Улица: 'Улица',
  Размер: 'Размер',
  Закрыть: 'Закрыть',
  'Спасибо за покупку': 'Спасибо за покупку',
  'Номер заказа': 'Номер заказа',
  'Адрес доставки': 'Адрес доставки',
  'Самовывоз из': 'Самовывоз из',
  'Сумма заказа': 'Сумма заказа',
  Доставка: 'Доставка',
  Бесплатно: 'Бесплатно',
  'Оплачено бонусами': 'Оплачено бонусами',
  Использовано: 'Использовано',
  Оплата: 'Оплата',
  Картой: 'Картой',
  Наличными: 'Наличными',
  'Картой в магазине': 'Картой в магазине',
  'К оплате': 'К оплате',
  'Оплачено картой': 'Оплачено картой',
  Успешно: 'Успешно',
  'Начислено бонусов': 'Начислено бонусов',
  'Стань участником бонусной программы и получи welcome-бонус':
    'Стань участником бонусной программы и получи welcome-бонус',
  'Стать участником': 'Стать участником',
  'На главную': 'На главную',
  'Отслеживать заказ': 'Отслеживать заказ',
  'Будьте в курсе акций': 'Будьте в курсе акций',
  'Отследить заказ': 'Отследить заказ',
  Товаров: 'Товаров',
  'На сумму': 'На сумму',
  Скидка: 'Скидка',
  'Использовано бонусов': 'Использовано бонусов',
  Итого: 'Итого',
  'Бонусов за заказ': 'Бонусов за заказ',
  'Пользовательское соглашение': 'Пользовательское соглашение',
  'Подтверждая заказ, я принимаю': 'Подтверждая заказ, я принимаю',
  'условия пользовательского': 'условия пользовательского',
  соглашения: 'соглашения',
  'Постоянный клиент': 'Постоянный клиент',
  'Новый покупатель': 'Новый покупатель',
  'Контактная информация': 'Контактная информация',
  'Способ доставки': 'Способ доставки',
  'Доставка по адресу': 'Доставка по адресу',
  'Ваш заказ': 'Ваш заказ',
  'Редактировать заказ': 'Редактировать заказ',
  'Добавить комментарий': 'Добавить комментарий',
  Комментарий: 'Комментарий',
  'Не перезванивать мне': 'Не перезванивать мне',
  'Доступно к списанию': 'Доступно к списанию',
  'Оплатить бонусами': 'Оплатить бонусами',
  'Ваша скидка': 'Ваша скидка',
  Выгодная: 'Выгодная',
  Максимальная: 'Максимальная',
  'Выберите другую скидку': 'Выберите другую скидку',
  'Заказ верный, звонок менеджера не требуется': 'Заказ верный, звонок менеджера не требуется',
  'Сохраненные адреса': 'Сохраненные адреса',
  'Добавить адрес': 'Добавить адрес',
  'Способ оплаты': 'Способ оплаты',
  'Оплата картой': 'Оплата картой',
  'Оплата картой курьеру': 'Оплата картой курьеру',
  'Оплата картой "Пакунок малюка"': 'Оплата картой "Пакунок малюка"',
  'Картой курьеру': 'Картой курьеру',
  'Картой "Пакунок малюка"': 'Картою "Пакунок малюка"',
  'Для оплаты картой "Пакунок малюка" на e-mail будет или в приложение Приват 24':
    'Для оплаты картой "Пакунок малюка" на e-mail будет отправлен инвойс через LiqPay или в приложение Приват 24',
  'Для оплаты будет отправлен инвойс через LiqPay или в приложение Приват 24':
    'Для оплаты будет отправлен инвойс через LiqPay или в приложение Приват 24',
  'Ваш город': 'Ваш город',
  Телефон: 'Телефон',
  Имя: 'Имя',
  Фамилия: 'Фамилия',
  'Если вы хотите следить за статусом выполнения заказа': 'Если вы хотите следить за статусом выполнения заказа',
  'Для применения и просмотра бонусов, пожалуйста': 'Для применения и просмотра бонусов, пожалуйста',
  авторизуйтесь: 'авторизуйтесь',
  'Оплата кешбэком': 'Оплата кешбэком',
  'Ввести промокод': 'Ввести промокод',
  Промокод: 'Промокод',
  Cертификат: 'Cертификат',
  'Заказ подтверждаю': 'Заказ подтверждаю',
  Количество: 'Количество',
  Статуc: 'Статуc',
  Новый: 'Новый',
  'Готов к выдаче': 'Готов к выдаче',
  Отменен: 'Отменен',
  'Ожидается доставка': 'Ожидается доставка',
  'Отказ покупателя': 'Отказ покупателя',
  'Нова Пошта': 'Нова Пошта',
  Justin: 'Justin',
  'Мои заказы': 'Мои заказы',
  '№ заказа': '№ заказа',
  Статус: 'Статус',
  'У Вас пока нет заказов': 'У Вас пока нет заказов',
  'Предлагаем ознакомиться с нашими': 'Предлагаем ознакомиться с нашими',
  'Комментарий к заказу': 'Комментарий к заказу',
  'Есть комментарии к заказу?': 'Есть комментарии к заказу?',
  'Есть комментарии?': 'Есть комментарии?',
  'Оставить комментарий': 'Оставить комментарий',
  "Поле 'Комментарий' обязательно для заполнения": "Поле 'Комментарий' обязательно для заполнения",
  'Ошибка оформления заказа. К сожалению, все товары из вашей корзины закончились.':
    'Ошибка оформления заказа. К сожалению, все товары из вашей корзины закончились.',
  'Ошибка оформления заказа. Попробуйте оформить заказ повторно. Если ошибка повторяется, свяжитесь с нами по телефону (044) 323-09-06':
    'Ошибка оформления заказа. Попробуйте оформить заказ повторно. Если ошибка повторяется, свяжитесь с нами по телефону (044) 323-09-06',
  'Произвести оплату кешбэком при самовывозе возможно в точке выдачи заказа':
    'Произвести оплату кешбэком при самовывозе возможно в точке выдачи заказа',
  //ORDER
  //BLOG-NEWS-PROMO-BRANDS
  Воспитание: 'Воспитание',
  Развитие: 'Развитие',
  Досуг: 'Досуг',
  'Правильное питание': 'Правильное питание',
  Акции: 'Акции',
  Осталось: 'Осталось',
  'Акция завершена': 'Акция завершена',
  'До конца акции': 'До конца акции',
  //BLOG-NEWS-PROMO-BRANDS
  //MODALS
  Вход: 'Вход',
  Спасибо: 'Спасибо',
  'за подписку': 'за подписку',
  'Вы уже': 'Вы уже',
  подписаны: 'подписаны',
  Дарим: 'Дарим',
  'на первую покупку': 'на первую покупку',
  Получить: 'Получить',
  'Добавить в cписок желаний': 'Добавить в cписок желаний',
  'Выберите размер': 'Выберите размер',
  'Войти с помощью номера телефона': 'Войти с помощью номера телефона',
  Пароль: 'Пароль',
  'Или продолжить, используя социальные сети': 'Или продолжить, используя социальные сети',
  'Отправить пароль повторно': 'Отправить пароль повторно',
  'Восстановление пароля': 'Восстановление пароля',
  'Подтвердите свой номер телефона, чтобы войти в личный кабинет':
    'Подтвердите свой номер телефона, чтобы войти в личный кабинет',
  Далее: 'Далее',
  Здравствуйте: 'Здравствуйте',
  'Ваш Статус': 'Ваш Статус',
  'На Вашем счету': 'На Вашем счету',
  Получите: 'Получите',
  'для получения': 'для получения',
  'Станьте участником бонусной программы': 'Станьте участником бонусной программы',
  Соберите: 'Соберите',
  'и получите': 'и получите',
  'Просто заполните профиль в личном кабинете': 'Просто заполните профиль в личном кабинете',
  'На сайт': 'На сайт',
  'Вы хотите перейти в личный кабинет или вернуться на сайт':
    'Вы хотите перейти в личный кабинет или вернуться на сайт',
  'Установите ваш пароль': 'Установите ваш пароль',
  'Указать пароль позже': 'Указать пароль позже',
  'Введите пароль': 'Введите пароль',
  Сменить: 'Сменить',
  'двойные бонусы': 'двойные бонусы',
  'в честь': 'в честь',
  'дня рождения': 'дня рождения',
  'вашего малыша': 'вашего малыша',
  c: 'c',
  по: 'по',
  'Детские товары по программе Пакунок малюка': 'Детские товары по программе Пакунок малюка',
  //MODALS
  'Таблица размеров': 'Таблица размеров',
  Подтвердить: 'Подтвердить',
  'Быстрый заказ': 'Быстрый заказ',
  'Заказ в 1 клик': 'Заказ в 1 клик',
  'Просто добавьте свой телефон': 'Просто добавьте свой телефон',
  "Поле 'Е-mail' должно быть действительным электронным адресом":
    "Поле 'Е-mail' должно быть действительным электронным адресом",
  'Переместить в список желаний': 'Переместить в список желаний',
  'Удалить без сохранения': 'Удалить без сохранения',
  Ответить: 'Ответить',
  'Уже купил': 'Уже купил',
  'Товаров в вашем заказе': 'Товаров в вашем заказе',
  'На телефон': 'На телефон',
  'отправлен пароль': 'отправлен пароль',
  'Читать полностью': 'Читать полностью',
  Свернуть: 'Свернуть',
  Фильтры: 'Фильтры',
  'Список желаний пуст': 'Список желаний пуст',
  'Возможно, все желания владельца списка уже выполнены': 'Возможно, все желания владельца списка уже выполнены',
  Скрыть: 'Скрыть',
  'Ваш комментарий отправлен на обработку и появится в ближайшее время':
    'Ваш комментарий отправлен на обработку и появится в ближайшее время',
  '0 - 12 мес': '0 - 12 мес',
  '12 - 36 мес': '12 - 36 мес',
  '3 - 8 лет': '3 - 8 лет',
  '9 - 12 лет': '9 - 12 лет',
  '13 - 16 лет': '13 - 16 лет',
  Сохранено: 'Сохранено',
  'Ссылка скопирована!': 'Ссылка скопирована!',
  'Ошибка авторизации': 'Ошибка авторизации',
  'Введите номер телефона для продолжения регистрации': 'Введите номер телефона для продолжения регистрации',
  'Соцсеть подключена': 'Соцсеть подключена',
  'Мы нашли пользователя с таким телефоном среди наших клиентов. Это Вы? Тогда войдите на сайт с помощью номера телефона и пароля и привяжите социальную сеть в личном кабинете.':
    'Мы нашли пользователя с таким телефоном среди наших клиентов. Это Вы? Тогда войдите на сайт с помощью номера телефона и пароля и привяжите социальную сеть в личном кабинете.',
  'Ошибка отправки письма': 'Ошибка отправки письма',
  'Подарок за участие в акции': 'Подарок за участие в акции',
  'Выбрать подарок': 'Выбрать подарок',
  Отменить: 'Отменить',
  'Добавлен подарок': 'Добавлен подарок',
  'В вашу корзину добавлен подарок за участие в акции': 'В вашу корзину добавлен подарок за участие в акции',
  'Выберу потом': 'Выберу потом',
  'Есть промокод?': 'Есть промокод?',
  'Есть сертификат?': 'Есть сертификат?',
  Применить: 'Применить',
  'Применен сертификат на': 'Применен сертификат на',
  'Применен промокод на': 'Применен промокод на',
  'Введите код': 'Введите код',
  'Страница не найдена': 'Страница не найдена',
  'Недействительный промокод': 'Недействительный промокод',
  'Недействительный сертификат': 'Недействительный сертификат',
  'Не возможно добавить промо код': 'Не возможно добавить промо код',
  'Не возможно добавить сертификат': 'Не возможно добавить сертификат',
  'Вы исчерпали количество попыток': 'Вы исчерпали количество попыток',
  'Сегодня вы уже получили письмо с подтверждением': 'Сегодня вы уже получили письмо с подтверждением',
  'Обратитесь в техническую поддержку': 'Обратитесь в техническую поддержку',
  'Есть в магазинах': 'Есть в магазинах',
  'Белье дородовое': 'Белье дородовое',
  'Белье послеродовое': 'Белье послеродовое',
  'Одежда для беременных': 'Одежда для беременных',
  'Кнопка предзаказа': 'Предзаказ',
  'Приведи друга': 'Приведи друга',
  'Делитесь промокодом': 'Делитесь промокодом',
  'Копировать код': 'Копировать код',
  'Ваш промокод скоро будет тут! Попробуйте обновить страницу через несколько минут. Если промокод не появится - обратитель в call-центр по тел. (044) 323-09-06':
    'Ваш промокод скоро будет тут! Попробуйте обновить страницу через несколько минут. Если промокод не появится - обратитель в call-центр по тел. (044) 323-09-06',
  'Код скопирован!': 'Код скопирован!',
  'Ссылка на товар': 'Ссылка на товар',
  'кешбэк начисляется за информативный и одобренный модераторами отзыв на товар, приобретенный на сайте ma.com.ua или в магазинах MA':
    'кешбэк начисляется за информативный и одобренный модераторами отзыв на товар, приобретенный на сайте ma.com.ua или в магазинах MA',
  'Спасибо за подарок': 'Спасибо',
  Сертификаты: 'Сертификаты',
  'Оплачено "Частями от ПриватБанк"': 'Оплачено "Частями от ПриватБанк"',
  смотреть: 'смотреть',
  'Оплата частями': 'Оплата частями',
  'Оплата частями от ПриватБанк': 'Оплата частями от ПриватБанк',
  'Количество платежей': 'Количество платежей',
  'Сумма одного платежа': 'Сумма одного платежа',
  платежа: 'платежа',
  месяц: 'месяц',
  Выбрать: 'Выбрать',
  'Выберите количество платежей при оплате частями': 'Выберите количество платежей при оплате частями',
  'Необходимо выбрать количество платежей.': 'Необходимо выбрать количество платежей.',
  'Выберите количество платежей': 'Выберите количество платежей',
  'Частями от ПриватБанк': 'Частями от ПриватБанк',
  'Адресная доставка "Нова Пошта"': 'Адресная доставка "Нова Пошта"',
  'Доставка курьером по Украине (Meest)': 'Доставка курьером по Украине (Meest)',
  'Номер телефону має починатись з +380': 'Номер телефона должен начинаться с +380',
  'Вы успешно подтвердили email': 'Вы успешно подтвердили email',
  'Токен на найден, либо его время жизни истекло': 'Токен на найден, либо его время жизни истекло',
  'Ошибка подтверждения email': 'Ошибка подтверждения email',
  'Смотреть еще': 'Смотреть еще',
  monday: 'Пн',
  tuesday: 'Вт',
  wednesday: 'Ср',
  thursday: 'Чт',
  friday: 'Пт',
  saturday: 'Сб',
  sunday: 'Вс',
  Вихідний: 'Выходной',
  'На мапі': 'На карте',
  Список: 'Список',
  'Оберіть відділення на карті': 'Выбрать отделение на карте',
  'Статус оплаты': 'Статус оплаты',
  'Не успешно': 'Не успешно',
  'Время работы': 'Время работы',
  noscriptDefaultTitle: 'Скорее всего в вашем браузере отключён JavaScript.',
  noscriptDefaultText:
    'Вы должны включить JavaScript в вашем браузере, чтобы использовать все возможности этого сайта.',
  'Пользователь уже зарегистрирован.': 'Пользователь уже зарегистрирован.',
  'Превышен лимит попыток регистрации.': 'Превышен лимит попыток регистрации.',
  'Доступна оплата бонусами': 'Доступна оплата бонусами',
  'Оплата бонусами недоступна': 'Оплата бонусами недоступна',
  'на ваш счет': 'на ваш счет',
  'Быстро присоединиться': 'Быстро присоединиться',
  Всего: 'Всего',
  'чтобы заполнить анкету и активировать ваш аккаунт': 'чтобы заполнить анкету и активировать ваш аккаунт',
  'Выгодно покупать': 'Выгодно покупать',
  'Покупая любые продукты, вы получаете МА Кешбэк, который активируется на 16-й день. МА Кешбэк начисляется':
    'Покупая любые продукты, вы получаете МА Кешбэк, который активируется на 16-й день. МА Кешбэк начисляется',
  'даже во время акций и распродаж': 'даже во время акций и распродаж',
  'В зависимости от статуса в программе МА Кешбэк вы получаете 3, 5, 7 или 10% от покупки в виде кешбэка на ваш счет в программе МА Кешбэк':
    'В зависимости от статуса в программе МА Кешбэк вы получаете 3, 5, 7 или 10% от покупки в виде кешбэка на ваш счет в программе МА Кешбэк',
  'Выгодно использовать': 'Выгодно использовать',
  'Используйте больше бонусов за один раз, чтобы получить еще более выгодную скидку':
    'Используйте больше бонусов за один раз, чтобы получить еще более выгодную скидку',
  'Оплачивайте МА Кешбэком до 50% от стоимости продуктов, отмеченных диаМАнтом':
    'Оплачивайте МА Кешбэком до 50% от стоимости продуктов, отмеченных диаМАнтом',
  'МА Кешбэк можно использовать ': 'МА Кешбэк можно использовать ',
  'Легко использовать': 'Легко использовать',
  'Чем больше сумма покупок, тем больше процент начисления МА Кешбэка.':
    'Чем больше сумма покупок, тем больше процент начисления МА Кешбэка.',
  Присоединиться: 'Присоединиться',
  'Накапливайте бонусы': 'Накапливайте бонусы',
  'в программе': 'в программе',
  'Спасибо за ваш заказ!': 'Спасибо за ваш заказ!',
  'Стань участником бонусной программы': 'Стань участником бонусной программы',
  'Зарегистрируйся и получи welcome-бонус': 'Зарегистрируйся и получи welcome-бонус',
  'Много полезного тут': 'Много полезного тут',
  'Классные акции': 'Классные акции',
  'Акции, блог и крутые новинки': 'Акции, блог и крутые новинки',
  'зарегистрироваться или войти': 'зарегистрироваться или войти',
  'X2 бонусы в День рождения вашего ребенка': 'X2 бонусы в День рождения вашего ребенка',
  'Еще больше брендов-участников': 'Еще больше брендов-участников',
  'Еще больше брендов': 'Еще больше брендов-участников',
  'Собственный импорт': 'Собственный импорт',
  'На вашем счету недостаточно кешбэка. Пожалуйста, обновите страницу и попробуйте оформить заказ повторно.':
    'На вашем счету недостаточно кешбэка. Пожалуйста, обновите страницу и попробуйте оформить заказ повторно.',
  'К сожалению, программа МА Кешбэк временно недоступна. Попробуйте оформить заказ через несколько минут.':
    'К сожалению, программа МА Кешбэк временно недоступна. Попробуйте оформить заказ через несколько минут.',
  'Купить подарочные сертификаты': 'Купить подарочные сертификаты',
  'Более детально с правилами использования Подарочных сертификатов можно ознакомиться здесь':
    'Более детально с правилами использования Подарочных сертификатов можно ознакомиться здесь',
  'Правила использования подарочных сертификатов': 'Правила использования подарочных сертификатов',
  'Ліміт: до кг': 'Лимит: до {weight} кг',
  'Секретная скидка для будущих МА!': 'Секретная скидка для будущих МА!',
  'Чтобы получить промокод на секретную скидку, которая действует пока вы беременны':
    'Чтобы получить промокод на секретную скидку, которая действует пока вы беременны',
  зарегистрируйтесь: 'зарегистрируйтесь',
  'Секретная скидка по промокоду действует на': 'Секретная скидка по промокоду действует на',
  'Промокод действует на выбранный ассортимент ТМ Chicco** и Piccolino*** в указанную вами при регистрации предварительную дату родов плюс один месяц после их. Промокодом можно воспользоваться 3 раза во время покупок на сайтах ma.com.ua и chicco.com.ua, а также по всей сети магазинов МА и Chicco, с полным перечнем и адресами расположения которых можно ознакомиться на сайтах: ma.com.ua и chicco.com.ua. Скидка не добавляется к другим акционным предложениям и купонам. **Кико ***Пиколино':
    'Промокод действует на выбранный ассортимент ТМ Chicco** и Piccolino*** в указанную вами при регистрации предварительную дату родов плюс один месяц после их. Промокодом можно воспользоваться 3 раза во время покупок на сайтах ma.com.ua и chicco.com.ua, а также по всей сети магазинов МА и Chicco, с полным перечнем и адресами расположения которых можно ознакомиться на сайтах: ma.com.ua и chicco.com.ua. Скидка не добавляется к другим акционным предложениям и купонам. **Кико ***Пиколино',
  'Максимум 5 файлів': 'Максимум 5 файлов',
  'Власний імпорт': 'Собственный импорт',
  Дизайн: 'Дизайн',
  'Дата отправки сертификата на e-mail': 'Дата отправки сертификата на e-mail',
  Номиналы: 'Номиналы',
  'Текст поздравления': 'Текст поздравления',
  'МА Подписка': 'МА Подписка',
  'МА Подписка: до': 'МА Подписка: до',
  Подписка: 'Подписка',
  'Загрузить фото': 'Загрузить фото',
  'Мінімальна сума для оплати частинами': 'Минимальная сумма для оплаты частями 300 грн',
  'Максимальна сума для оплати частинами': 'Максимальная сумма для оплаты по частям 100000 грн',
  'Дата повинна бути сьогодні або пізніше': 'Дата должна быть сегодня или позже',
  'Поле обовязкове до заповнення': 'Поле {field} обязательно для заполнения',
  'Мінімальна довжина поля': 'Поле {field} должно содержать от {length} символов',
  'Максимальна довжина поля': 'Поле {field} не может быть больше {length} символов',
  'Тільки літери': 'Поле {field} может содержать только буквы',
  'Тільки числа': 'Поле {field} должно быть числовым',
  'Не співпадає': 'Поле {field} не совпадает с подтверждением',
  'Мінімальне значення': 'Поле {field} должно быть {min} или более',
  'Максимальне значення': 'Поле {field} должно быть {max} или меньше',
  'Тільки літери і тире': 'Поле {field} может содержать только буквы и тире',
  'Довжина поля': 'Поле {field} должно содержать {length} символов',
  'Введите корректное название города': 'Введите корректное название города',
  Продлить: 'Продлить',
  'Ваша МА Подписка': 'Ваша МА Подписка',
  'действует до': 'действует до',
  р: 'г',
  'МА Подписка действует даже во время акций и распродаж на бренды:':
    'МА Подписка действует на продукты, отмеченные диаМАнтом <img src="https://static.ma.com.ua/assets/svg/icon_vlasniy_import.svg">, даже во время акций и распродаж.',
  'для владельцев': 'для владельцев',
  'Выберите свой тариф МА Подписки': 'Выберите свой тариф МА Подписки',
  цена: 'цена',
  'Детальнее здесь': 'Детальнее здесь',
  'МА Підписка діє на бренди Власного імпорту навіть під час акцій та розпродажів.':
    'МА Подписка действует на бренды <b>Cобственного импорта</b> даже во время акций и распродаж.',
  'Помилка авторизації. Спробуйте пізніше або інший метод авторизації.':
    'Ошибка авторизации. Попытайтесь позже или другой метод авторизации.',
  'Спробуйте пізніше або інший метод авторизації.': 'Попытайтесь позже или другой метод авторизации.',
  '+400 бонусов за отзыв и +700 за отзыв с фото!*': '+400 бонусов за отзыв и +700 за отзыв с фото!*',
  'Получите 500 бонусов за первый отзыв или 700 бонусов за отзыв с фото!':
    'Получите 500 бонусов за первый отзыв или 700 бонусов за отзыв с фото!',
  'Данные получателя': 'Данные получателя',
  'E-mail получателя': 'E-mail получателя',
  'Дата отправки': 'Дата отправки',
  'Курьер "Нова Пошта"': 'Курьер "Нова Пошта"',
  'Отделение "Нова Пошта"': 'Отделение "Нова Пошта"',
  'Почтомат "Нова Пошта"': 'Почтомат "Нова Пошта"',
  'Отделение "Укрпошта"': 'Отделение "Укрпошта"',
  'Отделение "Justin"': 'Отделение "Justin"',
  'Курьер "Meest"': 'Курьер "Meest"',
  'Курьер по Киеву': 'Курьер по Киеву',
  Самовывоз: 'Самовывоз',
  'Курьер iPOST': 'Курьер iPOST',
  'Доставка в отделение': 'Доставка в отделение',
  'Максимальный номинал сертификата 100 000 грн': 'Максимальный номинал сертификата 100 000 грн',
  кешбэка: 'кешбэка',
  'МА Кешбэк': 'МА Кешбэк',
  'Сумма оплаты': 'Сумма оплаты',
  'Станьте участником программы': 'Станьте участником программы',
  'кешбэка за каждую покупку': 'кешбэка за каждую покупку',
  'до конца срока действия текущего статуса': 'до конца срока действия текущего статуса',
  'Оплачено кешбэком': 'Оплачено кешбэком',
  'Кешбэка за заказ': 'Кешбэка за заказ',
  'Начислено кешбэка': 'Начислено кешбэка',
  'Стань участником программы МА Кешбэк': 'Стань участником программы МА Кешбэк',
  'Зарегистрируйся и получи кешбэк на первую покупку': 'Зарегистрируйся и получи кешбэк на первую покупку',
  'Программа МА Кешбэк': 'Программа МА Кешбэк',
  'Присоединяйтесь к программе': 'Присоединяйтесь<br /> к программе',
  'двойной кешбэк': 'двойной кешбэк',
  'Оплатить кешбэком': 'Оплатить кешбэком',
  'Доступна оплата кешбэком': 'Доступна оплата кешбэком',
  'Оплата кешбэком недоступна': 'Оплата кешбэком недоступна',
  'Для просмотра баланса и оплаты кешбэком, пожалуйста': 'Для просмотра баланса и оплаты кешбэком, пожалуйста',
  'Получите 20 грн кешбэка за первый отзыв или 25 грн кешбэка за отзыв с фото!':
    'Получите 20 грн кешбэка за первый отзыв или 25 грн кешбэка за отзыв с фото!',
  '+15 грн кешбэка за отзыв и +25 грн кешбэка отзыв с фото!*':
    '+15 грн кешбэка за отзыв и +25 грн кешбэка отзыв с фото!*',
  'Станьте участником программы МА Кешбэк': 'Станьте участником программы МА Кешбэк',
  'Присоединяйтесь и получайте выгодный': 'Присоединяйтесь и получайте выгодный',
  'Накапливайте МА Кешбэк за каждую покупку и выбирайте лучшее для своих детей!':
    'Накапливайте МА Кешбэк за каждую покупку и выбирайте лучшее для своих детей!',
  '% начисления МА Кешбэка': '% начисления МА Кешбэка',
  'Срок действия статуса': 'Срок действия статуса',
  '1 год с момента приобретения': '1 год с момента приобретения',
  '2 года с момента приобретения': '2 года с момента приобретения',
  '1 год с момента последней покупки': '1 год с момента последней покупки',
  'Срок действия МА Кешбэка': 'Срок действия МА Кешбэка',
  'X2 МА Кешбэк ко Дню рождения вашего ребенка (от 3-х лет)':
    'X2 МА Кешбэк ко Дню рождения вашего ребенка (от 3-х лет)',
  'Подарок при обретении статуса': 'Подарок при обретении статуса',
  'Доступ к выбранным акциям за день до начала/закрытые распродажи':
    'Доступ к выбранным акциям за день до начала/закрытые распродажи',
  '* Сумма покупок за год с момента перехода на новый статус Guest, Friend или Family или за 2 года с момента перехода на статус Dynasty':
    '* Сумма покупок за год с момента перехода на новый статус Guest, Friend или Family или за 2 года с момента перехода на статус Dynasty',
  'Бренды-участники программы': 'Бренды-участники программы',
  'МА Кешбэк за отзывы на сайте': 'МА Кешбэк<br> за отзывы на сайте',
  'МА Кешбэк за отзывы в соцсетях': 'МА Кешбэк<br> за отзывы в соцсетях',
  'МА Кешбэк за регистрацию и подписку': 'МА Кешбэк<br> за регистрацию и подписку',
  'регистрация в программе и подписка на новости': 'регистрация в программе и подписка на новости',
  'подписка на рассылку писем': 'подписка на рассылку писем',
  'все фото- или видеоотзывы': 'все фото- или видеоотзывы',
  'первый текстовый отзыв': 'первый текстовый отзыв',
  'следующие текстовые отзывы': 'следующие текстовые отзывы',
  'отзыв в сториз (с отметкой аккаунта @ma.com.ua.official)':
    'отзыв в сториз<br> (с отметкой аккаунта @ma.com.ua.official)',
  'видео- или фотосообщение': 'видео- или фотосообщение',
  'партнеры программы': 'партнеры программы',
  'Минимальный номинал сертификата 50 грн': 'Минимальный номинал сертификата 50 грн',
  'Выберите магазин для самовывоза': 'Выберите магазин для самовывоза',
  'Выберите магазин': 'Выберите магазин',
  'Этот адрес уже был сохранен': 'Этот адрес уже был сохранен',
  'Выберите пол': 'Выберите пол',
  'Выберите дату': 'Выберите дату',
  'Мы вас узнали! У вас уже есть аккаунт': 'Мы вас узнали! У вас уже есть аккаунт',
  'Пользователь не зарегистрирован.': 'Пользователь не зарегистрирован.',
  'Неверный телефон или пароль.': 'Неверный телефон или пароль.',
  'Превышен лимит запросов в час.': 'Превышен лимит запросов в час.',
  'Превышен лимит запросов в минуту.': 'Превышен лимит запросов в минуту.',
  'Превышен лимит запросов.': 'Превышен лимит запросов.',
  'Неверный одноразовый код.': 'Неверный одноразовый код.',
  'Пароль не установлен. Воспользуйтесь кнопкой восстановления пароля для авторизации.':
    'Пароль не установлен. Воспользуйтесь кнопкой восстановления пароля для авторизации.',
  'Необходимо подтвердить номер телефона. Перейдите во вкладку "Регистрация" для завершения процедуры.':
    'Необходимо подтвердить номер телефона. Перейдите во вкладку "Регистрация" для завершения процедуры.',
  'Телефон уже зарегистрирован.': 'Телефон уже зарегистрирован.',
  Опубликован: 'Опубликован',
  'На модерации': 'На модерации',
  Отклонен: 'Отклонен',
  Начислено: 'Начислено',
  'за отзыв': 'за отзыв',
  'Срок действия кешбэка до': 'Срок действия кешбэка до',
  'Мы вас узнали!': 'Мы вас узнали!',
  'У вас уже есть аккаунт': 'У вас уже есть аккаунт',
  'Войти в аккаунт': 'Войти в аккаунт',
  Номинал: 'Номинал',
  'Название списка желаний': 'Название списка желаний',
  Название: 'Название',
  Преимущества: 'Переваги',
  'К-ство частей': 'К-ство частей',
  'Нет магазинов': 'Нет магазинов',
  'Отзывы о покупке товаров в категории': 'Отзывы о покупке товаров в категории',
  'Вознаграждение за отзывы на сайте': 'Вознаграждение за отзывы на сайте',
  'Вознаграждение за отзывы в соцсетях': 'Вознаграждение за отзывы в соцсетях',
  'Вознаграждение за регистрацию': 'Вознаграждение за регистрацию',
  'регистрация в программе МА Кешбэк': 'регистрация в программе МА Кешбэк',
  'Политика конфиденциальности': 'Политика конфиденциальности',
  'Программа МА Кешбєк': 'Программа МА Кешбєк',
  'Выберите город': 'Выберите город',
  'Отображать отделения, доступные для доставки заказа': 'Відображати відділення, доступні для доставки замовлення',
  'Изображение не удалось загрузить': 'Изображение {name} не удалось загрузить',
  'До начала акции': 'До начала акции'
};
