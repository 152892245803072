import { userStore } from 'u-store';
const { state, mutations, actions, getters } = userStore;

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
