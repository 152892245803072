<template>
  <div v-if="withContainer" class="container tablet-container">
    <div class="row no-gutters">
      <div class="col">
        <uc-breadcrumbs :links="breadcrumbsLinks" @changed="$emit('changed')" />
      </div>
    </div>
  </div>
  <uc-breadcrumbs v-else :links="breadcrumbsLinks" @changed="$emit('changed')" />
</template>

<script>
export default {
  props: {
    withContainer: {
      type: Boolean,
      default: false
    },
    breadcrumbsLinks: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" src="~/assets/scss/ui-kit/_uc-breadcrumbs.scss" />
