<template>
  <div class="auth-loading-spinner-wrapper">
    <div class="loading-spinner">
      <div class="loader-ma spinning-square">
        <div class="loader-ma__text">
          <svg
            class="loader-ma__text-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="62"
            height="48"
            viewBox="0 0 62 48"
            fill="none"
          >
            <path
              d="M0 0.345703H15.0541L16.6123 30.5153H17.14L18.7736 0.345703H33.8026V47.6542H23.3728L25.1069 17.8123H24.4786L21.2114 47.6542H12.566L9.19833 17.8123H8.62029L10.4549 47.6542H0V0.345703Z"
              fill="black"
            />
            <path
              d="M41.7694 0.345703H54.8884L61.9002 47.6542H49.686L48.5048 14.9642H48.0776L46.9969 47.6542H34.7827L41.7694 0.345703ZM45.9916 35.0016H51.7971V41.6556H45.9916V35.0016Z"
              fill="black"
            />
          </svg>
        </div>
        <div class="loader-ma__rect">
          <div class="loader-ma__rect-inner"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingSpinner'
};
</script>

<style lang="scss">
.auth-loading-spinner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;

  @media (max-width: 767px) {
    margin-top: 20px;
  }
}

.loading-spinner {
  height: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinning-square {
  width: 200px;
}
.loader-ma {
  height: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 4px;

  &__text {
    flex: 0 1 30%;
  }

  &__text-svg {
    width: 100%;
    height: 100%;
  }

  &__rect {
    flex: 0 1 70%;
    border-radius: 20px;
    position: relative;
    border: 2px solid $magic-mint;
    overflow: hidden;

    &-inner {
      position: absolute;
      left: 0;
      height: 100%;
      background-color: $magic-mint;
      animation-duration: 1.5s;
      animation-name: pulse;
      animation-iteration-count: infinite;
    }
  }

  @keyframes pulse {
    from {
      width: 0%;
    }

    to {
      width: 100%;
    }
  }
}
</style>
