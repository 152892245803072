import merge from '~lodash.mergewith'
import * as CoreSdk from '~@sentry/core'
import { captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } from '~@sentry/browser'
import { extraErrorDataIntegration } from '~@sentry/integrations'
import { init, browserTracingIntegration } from '~@sentry/vue'

export { init }
export const SentrySdk = { ...CoreSdk, ...{ captureUserFeedback, forceLoad, onLoad, showReportDialog, wrap } }

/** @type {string[]} */
const DISABLED_INTEGRATION_KEYS = ["ReportingObserver"]

/**
 * @typedef {Parameters<typeof init>[0]} InitConfig
 * @param {import('@nuxt/types').Context} ctx
 * @return {Promise<InitConfig>}
 */
export function getConfig (ctx) {
  /** @type {InitConfig} */
  const config = {
    dsn:"https:\u002F\u002F87c0fc66d6e1498397f7b3c0607c287b@o213294.ingest.sentry.io\u002F1353623",
    environment:"production",
    publishRelease:true,
    release:"BBS-UI production release 3eb3b1db",
    beforeSend:(event) => {
  try {
    if (process.client) {
      const XHRBreadcrumbs = event.breadcrumbs.filter((breadcrumb) => breadcrumb.category === 'xhr') || [];

      // Check for bot
      const isbot = require('isbot');
      const IGNORE_BOT_CODES = [469, 499];
      const IS_BOT = isbot.default(window.navigator.userAgent);

      const someBreadcrumbWithIngoreCode = XHRBreadcrumbs.some((breadcrumb) =>
      IGNORE_BOT_CODES.includes(breadcrumb.data?.status_code)
      );
      if (someBreadcrumbWithIngoreCode && IS_BOT) {
        return null;
      }

      // Network error, aborted
      const lastXHRWithNetworkError =
      XHRBreadcrumbs.length && !XHRBreadcrumbs[XHRBreadcrumbs.length - 1].data?.status_code;
      if (lastXHRWithNetworkError) {
        return null;
      }

      // Check for ProductPageDetector, possible from admitad
      if (event.exception?.values.some((exception) => exception.value?.includes('ProductPageDetector'))) {
        return null;
      }
    }
    return event;
  } catch (error) {
    console.log('Failed to define if sentry need to skip error', error);
  }
},
    tracesSampleRate:0.0002,
  }

  /** @type {NonNullable<InitConfig>['integrations']} */
  const resolvedIntegrations = [
    extraErrorDataIntegration(),
  ]

  resolvedIntegrations.push(browserTracingIntegration({
    router: ctx.app.router,
    ...{"routeLabel":"name"},
    ...{},
  }))
  merge(config, {"trackComponents":true}, {"tracesSampleRate":0.0002})

  config.integrations = (defaultIntegrations) => {
    return [
      ...defaultIntegrations.filter(integration => !DISABLED_INTEGRATION_KEYS.includes(integration.name)),
      ...resolvedIntegrations,
    ]
  }
  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
