import Vue from 'vue';
import Vuex from 'vuex';

import order from '~/store/modules/order';
import user from '~/store/modules/user';
import catalog from '~/store/modules/catalog';
import bonus from '~/store/modules/bonus';
import review from '~/store/modules/review';
import content from '~/store/modules/content';
import wishList from '~/store/modules/wishList';
import gift from '~/store/modules/gift';
import basket from '~/store/modules/basket';
import { DefaultDeliveryMethods } from '~/store/modules/order/DefaultDeliveryMethods.js';

const { mutationTypes: orderMutation } = order;

Vue.use(Vuex);

const store = () =>
  new Vuex.Store({
    strict: false,
    modules: {
      order,
      user,
      catalog,
      bonus,
      review,
      content,
      wishList,
      gift,
      basket
    },
    state: {
      refreshRequestTime: 0
    },
    mutations: {
      // refresh check
      SET_REFRESH_REQUEST_TIME(state, refreshRequestTime) {
        state.refreshRequestTime = refreshRequestTime;
      }
    },
    actions: {
      nuxtServerInit({ dispatch, commit }) {
        return Promise.all([
          dispatch('catalog/fetchCategoryConfig'),
          dispatch('catalog/fetchWarehouseConfig'),
          commit(`order/${orderMutation.SET_DELIVERY_METHODS}`, DefaultDeliveryMethods)
        ]);
      },
      // eslint-disable-next-line
      getCategoryMeta({ commit }, categoryName) {
        return this.$api.SearchService.getCategoryInfo(categoryName);
      },
      // Content Service
      getPageBySlug(context, params) {
        return this.$api.ContentService.getPageBySlug(params.slug, params.lang);
      }
    }
  });

export default store;
