export const DefaultDeliveryMethods = [
  {
    type: 2,
    carrier: 0,
    iconImageUrl: '7268b139a6242928bb758093ded7afec.svg',
    iconImageUrlSelected: '7268b139a6242928bb758093ded7afec.svg',
    price: 69,
    shortDescription: '1-2 дня',
    title: 'Курьер по Киеву',
    changeable: true,
    slug: 'im-courier'
  },
  {
    type: 2,
    carrier: 1,
    iconImageUrl: 'courier_not_active.svg',
    iconImageUrlSelected: 'courier_active.svg',
    price: 50,
    shortDescription: '2-5 дней',
    title: 'Доставка курьером по Украине (Meest)',
    changeable: true,
    slug: 'meest-courier'
  },
  {
    type: 3,
    carrier: 0,
    iconImageUrl: '8c3f5eff3784c4cf923b0978e9a120e2.svg',
    iconImageUrlSelected: '8c3f5eff3784c4cf923b0978e9a120e2.svg',
    price: 0,
    shortDescription: '30 минут - 2 дня',
    title: 'Самовывоз',
    slug: 'pick-and-collect'
  },
  {
    type: 1,
    carrier: 2,
    iconImageUrl: '3e08a4a8fe1e025922406adf59ee2d62.svg',
    iconImageUrlSelected: '3e08a4a8fe1e025922406adf59ee2d62.svg',
    price: 79,
    shortDescription: '1-3 дня',
    title: 'Отделение "Нова Пошта"',
    slug: 'np-warehouse'
  },
  {
    type: 2,
    carrier: 2,
    iconImageUrl: '3e08a4a8fe1e025922406adf59ee2d62.svg',
    iconImageUrlSelected: '3e08a4a8fe1e025922406adf59ee2d62.svg',
    price: 89,
    shortDescription: '1-2 дня',
    slug: 'np-courier',
    title: 'Адресная доставка "Нова Пошта"'
  }
];
