<template>
  <main class="main">
    <app-breadcrumbs :with-container="true" :breadcrumbs-links="breadcrumbsLinks" />
    <div class="container">
      <section v-if="error.statusCode === 404" role="404">
        <section class="not-found">
          <div class="container">
            <div class="row">
              <div class="col">
                <h3 class="not-found-title">{{ $t('Страница не найдена') }}</h3>

                <div class="not-found-box">
                  <img :src="bannerSrc" alt="404" />
                </div>

                <div class="not-found-head">
                  <uc-link :to="localePath({ name: 'promotion-page' })">
                    <uc-button class="uc-button-black not-found-button">{{ $t('Акции и распродажи') }}</uc-button>
                  </uc-link>
                  <uc-link :to="localePath({ name: 'main' })">
                    <uc-button class="uc-button-black not-found-button">{{ $t('На главную') }}</uc-button>
                  </uc-link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  </main>
</template>

<script>
import ScrollTop from '~/src/mixins/ScrollToTop.mixin';
import ScreenWidth from '~/src/mixins/ScreenWidth';
import metaRu from '~/src/meta/ru/404Meta.json';
import metaUa from '~/src/meta/ua/404Meta.json';
import AppBreadcrumbs from '~/src/components/general/AppBreadcrumbs.vue';

export default {
  components: {
    AppBreadcrumbs
  },
  mixins: [ScrollTop, ScreenWidth],
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  head() {
    return this.$metaService.getImportedMetaByLang(metaRu, metaUa, this.$i18n.locale);
  },
  computed: {
    bannerSrc() {
      return `https://static.ma.com.ua/assets/img/not-found/404_${this.isScreenMobile ? 'mobile' : 'desktop'}_${
        this.$i18n.locale
      }.png`;
    },
    breadcrumbsLinks() {
      return [
        {
          routeName: 'error',
          title: this.$t('Страница не найдена')
        }
      ];
    }
  },
  beforeMount() {
    this.scrollToTopMain();
  }
};
</script>

<style lang="scss" src="~/assets/scss/not-found/_not-found.scss" />
