<template>
  <section>
    <div class="container tablet-container">
      <div class="main-description">
        <input id="main-description-button" v-model="checked" type="checkbox" />
        <label for="main-description-button" class="main-description-button">
          <span v-if="$i18n.locale === 'ru'" class="uc-button button-swither">
            <img
              v-if="checked"
              src="https://static.ma.com.ua/assets/img/main/button-close_ru.png"
              width="268"
              height="45"
              alt="Read completely"
            />
            <img
              v-else
              src="https://static.ma.com.ua/assets/img/main/button-more_ru.png"
              width="268"
              height="45"
              alt="collapse"
            />
          </span>
          <span v-if="$i18n.locale === 'ua'" class="uc-button button-swither">
            <img
              v-if="checked"
              src="https://static.ma.com.ua/assets/img/main/button-close_ua.png"
              width="268"
              height="45"
              alt="Read completely"
            />
            <img
              v-else
              src="https://static.ma.com.ua/assets/img/main/button-more_ua.png"
              width="268"
              height="45"
              alt="collapse"
            />
          </span>
        </label>
        <div class="description-block">
          <uc-render-html root-element="section" class="description-block-text" :html="description" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MainDescription',
  props: {
    description: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: false
    };
  }
};
</script>

<style lang="scss" src="~/assets/scss/main-description/_main-description.scss" />
