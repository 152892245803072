// @deprecated
// @todo: replace to u-mixins
export default {
  methods: {
    scrollToTop() {
      if (!Object.prototype.hasOwnProperty.call(global, 'scrollTo')) {
        return null;
      }

      global.scrollTo({
        top: 140,
        behavior: 'smooth'
      });
    },
    scrollToTopMain() {
      if (!Object.prototype.hasOwnProperty.call(global, 'scrollTo')) {
        return null;
      }

      global.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    setScrollToTop(top) {
      if (!Object.prototype.hasOwnProperty.call(global, 'scrollTo')) {
        return null;
      }

      global.scrollTo({
        top: top,
        behavior: 'smooth'
      });
    }
  }
};
