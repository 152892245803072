import Vue from 'vue';
import Router from 'vue-router';

import IndexPage from '~/pages/main.vue';
const NotFoundErrorPage = () =>
  import(/* webpackChunkName: "notfounderrorpage" */ '~/pages/404.vue').then((res) => res.default);
const BasketPage = () =>
  import(/* webpackChunkName: "basketpage" */ '~/pages/basket/index.vue').then((res) => res.default);
const chiccoTime = () =>
  import(/* webpackChunkName: "chiccotime" */ '~/pages/chicco-time/index.vue').then((res) => res.default);
const DetailPage = () =>
  import(/* webpackChunkName: "detailpage" */ '~/pages/detail/_id/index.vue').then((res) => res.default);
const InfoPage = () => import(/* webpackChunkName: "infopage" */ '~/pages/info/index.vue').then((res) => res.default);
const OrderPage = () =>
  import(/* webpackChunkName: "orderpage" */ '~/pages/order/index.vue').then((res) => res.default);
const OrderHistoryListPage = () =>
  import(/* webpackChunkName: "orderhistorylistpage" */ '~/pages/order-history-list/index.vue').then(
    (res) => res.default
  );
const OrderHistoryDetailPage = () =>
  import(/* webpackChunkName: "orderhistorydetailpage" */ '~/pages/order-history-detail/index.vue').then(
    (res) => res.default
  );
const UserReviewPage = () =>
  import(/* webpackChunkName: "userreviewpage" */ '~/pages/user-review/index.vue').then((res) => res.default);
const ProfilePage = () =>
  import(/* webpackChunkName: "profilepage" */ '~/pages/profile/index.vue').then((res) => res.default);
const EmailConfirmation = () =>
  import(/* webpackChunkName: "emailconfirmation" */ 'u-auth').then((res) => res.EmailConfirmation);
const SearchResultPage = () =>
  import(/* webpackChunkName: "searchresultpage" */ '~/pages/catalog/search-result.vue').then((res) => res.default);
const ThanksPaymentPage = () =>
  import(/* webpackChunkName: "thankspaymentpage" */ '~/pages/thanks/payment.vue').then((res) => res.default);
const ThanksOrderPage = () =>
  import(/* webpackChunkName: "thanksorderpage" */ '~/pages/thanks/order.vue').then((res) => res.default);
const BonusProgramPage = () =>
  import(/* webpackChunkName: "bonusprogrampage" */ '~/pages/bonus-program/index.vue').then((res) => res.default);
// const InviteFriendPage = () => import(/* webpackChunkName: "invitefriendpage" */ '~/pages/invite-friend');
const SubscriptionProfile = () =>
  import(/* webpackChunkName: "subscription" */ '~/pages/subscription-profile/index.vue').then((res) => res.default);
const OauthConnectPage = () =>
  import(/* webpackChunkName: "oauthconnectpage" */ '~/pages/oauth/connect/index.vue').then((res) => res.default);
const OauthPage = () =>
  import(/* webpackChunkName: "oauthpage" */ '~/pages/oauth/index.vue').then((res) => res.default);
const Brand = () => import(/* webpackChunkName: "brand" */ '~/pages/brand/index.vue').then((res) => res.default);
const BrandList = () => import(/* webpackChunkName: "brandlist" */ '~/pages/brand/list.vue').then((res) => res.default);
const WishList = () =>
  import(/* webpackChunkName: "wishlist" */ '~/pages/wish-list/index.vue').then((res) => res.default);
const PublicWishList = () =>
  import(/* webpackChunkName: "publicwishlist" */ '~/pages/wish-list/_hash/index.vue').then((res) => res.default);
const Promotion = () =>
  import(/* webpackChunkName: "promotion" */ '~/pages/promotion/index.vue').then((res) => res.default);
const PromotionDetail = () =>
  import(/* webpackChunkName: "promotiondetail" */ '~/pages/promotion-detail/index.vue').then((res) => res.default);
const PersonalArea = () =>
  import(/* webpackChunkName: "personalarea" */ '~/pages/personal-area/index.vue').then((res) => res.default);
const Blog = () => import(/* webpackChunkName: "blog" */ '~/pages/blog/index.vue').then((res) => res.default);
const BlogDetail = () =>
  import(/* webpackChunkName: "blogdetail" */ '~/pages/blog-detail/index.vue').then((res) => res.default);
const News = () => import(/* webpackChunkName: "news" */ '~/pages/news/index.vue').then((res) => res.default);
const NewsDetail = () =>
  import(/* webpackChunkName: "newsdetail" */ '~/pages/news-detail/index.vue').then((res) => res.default);
const CatalogProductsPage = () =>
  import(/* webpackChunkName: "catalogproductspage" */ '~/pages/catalog/products.vue').then((res) => res.default);
const CustomCatalogPage = () =>
  import(/* webpackChunkName: "custom-catalog" */ '~/pages/catalog/customCatalog.vue').then((res) => res.default);
const UiKit = () => import(/* webpackChunkName: "uikit" */ '~/pages/ui-kit/index.vue').then((res) => res.default);
const HealthCheck = () =>
  import(/* webpackChunkName: "healthcheck" */ '~/pages/health-check/index.vue').then((res) => res.default);

const i18nConf = require('./i18n.conf.json');
import { getLocalizedRouteName } from '~/utils/i18n-url';
import routerPath from '~/router-path.js';

const routeConfig = [
  { name: 'main', path: routerPath.main, component: IndexPage },
  { name: 'not-found-error-page', path: routerPath.notFoundErrorPage, component: NotFoundErrorPage },
  { name: 'basket-page', path: routerPath.basket, component: BasketPage, meta: { isAuthorized: true } },
  { name: 'chicco-time', path: routerPath.chiccoTime, component: chiccoTime },
  { name: 'product-detail-page', path: routerPath.product, component: DetailPage },
  { name: 'info-page', path: routerPath.page, component: InfoPage },
  { name: 'order-page', path: routerPath.order, component: OrderPage },
  {
    name: 'order-history-page',
    path: routerPath.orderHistory,
    component: OrderHistoryListPage,
    meta: { isAuthorized: true }
  },
  { name: 'order-history-detail-page', path: routerPath.orderHistoryDetail, component: OrderHistoryDetailPage },
  {
    name: 'user-review-page',
    path: routerPath.userReviewPage,
    component: UserReviewPage,
    meta: { isAuthorized: true }
  },
  { name: 'profile-page', path: routerPath.profile, component: ProfilePage, meta: { isAuthorized: true } },
  { name: 'email-confirm', path: routerPath.emailConfirm, component: EmailConfirmation },
  { name: 'search-result-page', path: routerPath.searchResult, component: SearchResultPage },
  { name: 'thanks-payment-page', path: routerPath.thanksPayment, component: ThanksPaymentPage },
  { name: 'thanks-order-page', path: routerPath.thanksOrder, component: ThanksOrderPage },
  { name: 'bonus-program-page', path: routerPath.bonusProgram, component: BonusProgramPage },
  // { name: 'invite-friend-page', path: routerPath.inviteFriend, component: InviteFriendPage },
  {
    name: 'subscription-profile-page',
    path: routerPath.subscriptionProfile,
    component: SubscriptionProfile,
    meta: { isAuthorized: true }
  },
  { name: 'oauth', path: routerPath.oauth, component: OauthPage },
  { name: 'oauth-connect', path: routerPath.oauthConnect, component: OauthConnectPage },
  { name: 'wish-list-page', path: routerPath.wishList, component: WishList, meta: { isAuthorized: true } },
  { name: 'public-wish-list-page', path: routerPath.publicWishList, component: PublicWishList },
  { name: 'promotion-page', path: routerPath.promotion, component: Promotion },
  { name: 'promotion-detail-page', path: routerPath.promotionDetail, component: PromotionDetail },
  { name: 'blog-page', path: routerPath.blog, component: Blog },
  { name: 'blog-detail-page', path: routerPath.blogDetail, component: BlogDetail },
  { name: 'news-page', path: routerPath.news, component: News },
  { name: 'news-detail-page', path: routerPath.newsDetail, component: NewsDetail },
  { name: 'personal-area-page', path: routerPath.personalArea, component: PersonalArea, meta: { isAuthorized: true } },
  { name: 'brand-list', path: routerPath.brands, component: BrandList },
  { name: 'brand-show', path: routerPath.brand, component: Brand },
  { name: 'health-check', path: routerPath.healthCheck, component: HealthCheck },
  { name: 'outlet-page', path: routerPath.outlet, component: CustomCatalogPage },
  { name: 'pakunok-malyuka-page', path: routerPath.pakunokMalyuka, component: CustomCatalogPage },
  { name: 'custom-catalog-page', path: routerPath.customCatalog, component: CustomCatalogPage },
  { name: 'product-catalog-page', path: routerPath.catalog, component: CatalogProductsPage },
  //this should be last
  { name: 'error', path: '/:urlBlock(.*)*', component: NotFoundErrorPage }
];

if (process.env.NODE_ENV === 'development') {
  routeConfig.unshift({ name: 'ui-kit-page', path: '/ui-kit', component: UiKit });
}

Vue.use(Router);

export function createRouter() {
  const routes = routeConfig.reduce((list, { name, path, component, ...other }) => {
    i18nConf.locales.forEach((locale) => {
      list.push({
        ...other,
        name: getLocalizedRouteName(name, locale.code),
        path: locale.code !== i18nConf.hiddenLocale ? `/${locale.code}${(path !== '/' && path) || ''}` : path,
        component
      });
    });

    return list;
  }, []);

  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    routes: routes
  });
}
