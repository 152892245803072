<template>
  <div class="container tablet-container">
    <div class="row no-gutters">
      <div class="bb_wrapper">
        <div class="bb__col">
          <div class="bb__header">
            <h2 class="bb__title">{{ blogSlides.title }}</h2>
            <uc-link
              class="bb__more"
              title="Смотреть все"
              aria-label="Смотреть все посты"
              :to="localePath({ name: 'blog-page' })"
              >{{ $t('Смотреть все') }}</uc-link
            >
          </div>
          <uc-link
            v-if="blogSlides.slides.length && blogSlides.slides[0].slug"
            :to="localePath({ name: 'blog-detail-page', params: { slug: blogSlides.slides[0].slug } })"
            class="bb__blog"
            :title="blogSlides.slides[0].h1"
            :aria-label="blogSlides.slides[0].h1"
          >
            <div class="bb__blog-banner">
              <uc-nuxt-image
                :img="blogSlides.slides[0].sliderImage"
                alt="card"
                :width="`900`"
                :height="`450`"
                folder="content-image"
                :lazy="true"
              />
            </div>
            <div class="bb__blog-text">{{ blogSlides.slides[0].h1 }}</div>
          </uc-link>
          <loader v-else />
        </div>
        <div class="bb__col">
          <div v-if="profile && isActiveBonusProgram && isBonusProgramMember" class="bb__bonus">
            <div class="bb__header">
              <h2 class="bb__title">{{ $t('МА Кешбэк') }}:</h2>
            </div>
            <uc-link :to="localePath({ name: 'bonus-program-page' })" class="bb__bonus">
              <div class="bb__bonus-wrap">
                <div class="bb__bonus-banner bb__bonus-banner_user">
                  <div class="bb__bonus-banner-anon">
                    <div class="bb__bonus-banner-text-anon">{{ $t('Доступно кешбэка') }}:</div>
                  </div>
                  <div class="bb__bonus-text-wrap">
                    <div class="bb__bonus-text-count">{{ profile.activeAmount | currency }}</div>
                  </div>
                  <div class="bb__bonus-coin">
                    <img
                      :src="`https://static.ma.com.ua/assets/svg/ma-cashback_${$i18n.locale}.svg`"
                      alt="ma-cashback"
                    />
                  </div>
                </div>
              </div>
            </uc-link>
            <div class="bb__bonus-text">
              {{ $t('Накапливайте кешбэк за каждую покупку и выбирайте лучшее для своих детей!') }}
            </div>
          </div>
          <div v-else-if="isActiveBonusProgram && !isBonusProgramMember" class="bb__bonus">
            <div class="bb__header">
              <h2 class="bb__title">{{ $t('МА Кешбэк') }}</h2>
            </div>
            <uc-link :to="localePath({ name: 'info-page', params: { slug: 'bonus-rules' } })" class="bb__bonus">
              <div class="bb__bonus-wrap">
                <div class="bb__bonus-banner">
                  <div class="bb__bonus-banner-anon">
                    <uc-render-html
                      class="bb__bonus-banner-text-anon"
                      :root-element="'div'"
                      :html="$t('Присоединяйтесь к программе')"
                    />
                    <div class="bb__bonus-babycard-anon">
                      <img
                        :src="`https://static.ma.com.ua/assets/svg/ma-cashback_${$i18n.locale}.svg`"
                        alt="ma-cashback"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="bb__bonus-text">
                {{ $t('Накапливайте кешбэк за каждую покупку и выбирайте лучшее для своих детей!') }}
              </div>
            </uc-link>
          </div>
          <loader v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScreenWidth from '~/src/mixins/ScreenWidth';
import BonusProgramMemberMixin from '~/src/mixins/BonusProgramMember';
import Loader from '~/src/components/page-blocks/Loader.vue';

export default {
  components: { Loader },
  mixins: [ScreenWidth, BonusProgramMemberMixin],
  props: {
    blogSlides: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    profile() {
      return this.$store.state.bonus.profile;
    }
  },
  created() {
    this.$bonus.getBonusProfileIfNotExist();
  }
};
</script>

<style lang="scss" src="~/assets/scss/blog-and-bonus/_blog-and-bonus.scss" />
